import { Link } from "gatsby"
import React from "react"
import { Navbar } from "react-bootstrap"

import logo from "../assets/images/logo/logo.svg"

export default function PrivacyPolicy() {
  const currentYear = new Date().getFullYear()

  return (
    <div className="footer-content-container">
      <Navbar className="bg-dark d-flex justify-content-start w-100 p-0 m-0">
        <Navbar.Brand className="d-flex justify-content-center align-items-center ps-2">
          <img alt="" src={logo} width="45" height="45" />
          <Link to="/" className="logo-text">
            Folksmedia
          </Link>
        </Navbar.Brand>
      </Navbar>

      <div className="footer-content-sub-container justify-text">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h6 className="fw-bold">Privacy Policy</h6>
            <i>Dated: November 22, 2021</i>
          </div>

          <div>
            <h6>
              <i>RKR 8.24.2022</i>
            </h6>
          </div>
        </div>
        <br />
        <br />
        <br />
        <div>
          <p className="lh-lg">
            Folksmedia respects your privacy. Folksmedia is a subsidiary of
            “Adcuratio Media, Inc” (“Adcuratio,” “Folkmedia,” “we,” or “us”). We
            are committed to protecting the privacy of our users, and we want to
            provide a safe and secure user experience. We will use our best
            efforts to ensure that the information you submit to us remains
            private and is used only for the purposes set forth herein. This
            policy details how data about you is collected, used and shared when
            you access our website and services or interact with us
            (collectively, the “Folksmedia Services”, or “Folksmedia”).
            Folksmedia and Folks Media are registered trademarks of M/s
            Folksmedia, Inc.
          </p>
        </div>
        <div>
          <h5 className="fw-bold">Applicability of Privacy Policy</h5>

          <p className="lh-lg">
            This privacy policy applies to all information we collect through
            our websites and mobile applications (the “Folksmedia Services”)
            from current and former Folksmedia users and visitors. This policy
            also covers our treatment of any personally identifiable information
            that our business partners share with us.
          </p>
        </div>{" "}
        <div>
          <h5 className="fw-bold">Information Collected from You</h5>
          <p className="lh-lg">
            When you subscribe for or use the Folksmedia Services, or contact us
            for information, we collect the following personal information
            directly from you: your full name, email address and/or phone number
            and name of employer, if applicable. To open a user account, the
            user’s email address is required.
          </p>
          <p className="lh-lg">
            By voluntarily providing us with this data, you are consenting to
            our use of it in accordance with this Privacy Policy. You may
            directly revise, correct or delete the personal information you
            provide via your Folksmedia user account. If in the course of using
            our services you opt to provide additional information, such as
            upload a profile picture, then this information will be used in
            accordance with this Privacy Policy. Pictures uploaded by a user as
            their account profile pictures are used by Folksmedia for such
            purpose only.
          </p>{" "}
          <p className="lh-lg">
            If you opt to activate voice commands on your device when using the
            Folksmedia mobile application, we also collect voice samples for
            search and voice commands, which voice data is then sent by the
            Folksmedia application to Google Android’s or Apple iOS’s (as
            applicable) built-in speech-to-text APIs/functions for
            conversion-to-text for further processing. The voice samples are not
            stored or processed by Folksmedia and are subject to the applicable
            Google or Apple privacy policy. Please refer{" "}
            <a
              href="  https://www.apple.com/legal/privacy/en-ww/"
              target="_blank"
              rel="noreferrer"
              className="primary-text-color"
            >
              https://www.apple.com/legal/privacy/en-ww/
            </a>{" "}
            and{" "}
            <a
              href="   https://policies.google.com/privacy?hl=en-US"
              target="_blank"
              rel="noreferrer"
              className="primary-text-color"
            >
              https://policies.google.com/privacy?hl=en-US
            </a>{" "}
            for Apple and Google’s privacy policies.
          </p>
          <p className="lh-lg">
            If you opt to share your contacts, these will be used to show you
            better recommendations. The shared contacts will be stored in
            anonymized (hashed) form by the Folksmedia Services subject to this
            Privacy Policy.
          </p>
          <p className="lh-lg">
            In addition, the Folksmedia websites and/or applications also
            automatically receive information on our server from each user’s
            browser and from Folksmedia cookie or cookies, including: SSID names
            of wifi network connections available to the user, coarse location
            of user and browser type. Folksmedia collects this data to operate
            effectively and provide better quality experiences.
          </p>
          <p className="lh-lg">
            Lastly, our websites are powered by our service provider Shopify,
            which may collect certain information directly from you or from your
            browser (via Shopify cookie(s)), that is not shared with Folksmedia
            and not subject to this privacy policy. For more information on the
            data collected by Shopify, please see Shopify’s privacy policy:{" "}
            <a
              href=" www.shopify.com/legal/privacy/customers"
              target="_blank"
              rel="noreferrer"
              className="primary-text-color"
            >
              www.shopify.com/legal/privacy/customers
            </a>{" "}
            .
          </p>
          <p className="lh-lg">
            For more information about cookies, and how to disable cookies,
            visit http://www.allaboutcookies.org. Note that certain of our
            Services require certain cookies to be enabled; if all cookies are
            disabled, not all Services may be available to you. Certain aspects
            and features of the Folksmedia Services are only available through
            the use of Cookies, so if you choose to disable or decline Cookies,
            your use of the Folksmedia Services may be limited or not possible.
          </p>
          <p className="lh-lg">
            Before permitting you to use Folksmedia’s Services, we may require
            additional information from you that we can use to verify your
            identity, address or other information. If you do not agree to our
            collection of the information, you may be limited or restricted in
            the Folksmedia Services available to you.
          </p>
        </div>
        <div>
          <h5 className="fw-bold">
            Personal Information We Receive from Third Parties
          </h5>

          <p className="lh-lg">
            We do not receive personal information from third parties currently
            but may do so in the future, relating to prospective customers, via
            marketing campaigns run by third parties on behalf of Folksmedia.
            Personal information received by Folksmedia from third party
            websites or applications is subject to this Privacy Policy.
          </p>
        </div>{" "}
        <div>
          <h5 className="fw-bold">How Folksmedia Uses Personal Information</h5>

          <p className="lh-lg">
            Folksmedia stores and uses personal information it collects to:
          </p>
          <ul className="lh-lg">
            <li>Provide, maintain and improve the Adcuratio Services;</li>
            <li>
              communicate with you, manage our relationship with you, which
              includes sending administrative information to you, such as
              changes to our terms, conditions, and policies, and respond to
              customer and prospective customer queries;
            </li>
            <li>
              Help protect the safety of our users, which includes, without
              limitation, blocking suspected spammers, investigating and
              addressing abuse and enforcing the Folksmedia Terms of Service and
              this Privacy Policy;
            </li>
            <li>
              Inform you of updates to the Adcuratio Services, security alerts
              and other support and administrative messages;
            </li>
            <li>
              Communicate with you about products, services, offers, promotions
              and events and provide other news and information we think will be
              of interest to you (for information about how to opt out of these
              communications, see “Choices” below);
            </li>
            <li>
              Monitor and analyze trends, usage and activities in connection
              with the Adcuratio Services to improve your experience and our
              service offerings;
            </li>
            <li>
              diagnose or fix technological issues; prevent fraud or criminal
              activities, misuse of our products or services and ensure the
              security of our IT systems, architecture and networks;
            </li>
            <li>comply with legal obligations and legal process;</li>
            <li>
              respond to requests from public and government authorities,
              including public and government authorities outside your country
              of residence; and
            </li>
            <li>protect our operations.</li>
          </ul>
        </div>
        <div>
          <h5 className="fw-bold">External Websites and Services</h5>

          <p className="lh-lg">
            The Folksmedia Services may contain links to third-party websites or
            applications, including for purposes of providing account linking
            services or payment services. Folksmedia has no control over the
            privacy practices or the content of any of these websites or
            applications. As such, we are not responsible for the practices
            employed by websites or services linked to or from the Folksmedia
            Services, including the information or content contained therein.
            Please remember that when you use a link to go from the Folksmedia
            Services to another website or applications, our Privacy Policy does
            not apply to third-party websites or services. You should check the
            applicable third-party privacy policy and terms of service when
            visiting any other websites or applications, and before providing
            any personal information to such external websites or applications.
            Your browsing and interaction on any third-party website or service,
            including those that have a link or advertisement on our website,
            are subject to that third party’s own rules and policies.
          </p>
        </div>
        <div>
          <h5 className="fw-bold">Sharing on Social Media</h5>

          <p className="lh-lg">
            The Folksmedia Services may offer social sharing features or other
            integrated tools that let you share content or action you take using
            the Folksmedia Services with other media. Your use of these features
            enables the sharing of certain information with your friends,
            network or the public, depending on the settings you establish with
            the third party that provides the social sharing feature. For more
            information about the purpose and scope of data collection and
            processing in connection with social sharing features, please visit
            the privacy policies of the third parties that provide these social
            sharing features.
          </p>
        </div>
        <div>
          <h5 className="fw-bold">How We Share Personal Information</h5>
          <p className="lh-lg">
            We share the personal information we collect and receive only with
            trusted third parties and business partners to help us use such
            personal information to provide services to you, as described
            herein. Information shared by Folksmedia remains subject to this
            privacy policy.
          </p>{" "}
          <p className="lh-lg">
            For the hosting of and data storage for the Folksmedia Services, we
            use Amazon Web Services (AWS). The Folksmedia Services and related
            databases are hosted on servers located in the United States. For
            information relating to AWS’s data privacy and security practices,
            please consult:{" "}
            <a
              href="https://aws.amazon.com/privacy/"
              target="_blank"
              rel="noreferrer"
              className="primary-text-color"
            >
              https://aws.amazon.com/privacy/
            </a>{" "}
            . For content delivery services, the Services use bunny.net, which
            allows anonymization of personal data and encryption (for
            information on its privacy policy, see here:{" "}
          </p>
          <p className="lh-lg">
            ({" "}
            <a
              href="https://bunny.net/privacy"
              target="_blank"
              rel="noreferrer"
              className="primary-text-color"
            >
              https://bunny.net/privacy
            </a>{" "}
            ) and/or Amazon CloudFront (see{" "}
            <a
              href="https://aws.amazon.com/privacy/"
              target="_blank"
              rel="noreferrer"
              className="primary-text-color"
            >
              https://aws.amazon.com/privacy/
            </a>{" "}
            ).
          </p>
          <p className="lh-lg">
            In the event of a merger, reorganization, dissolution or similar
            corporate event, or the sale of all or substantially all of our
            assets, we expect that the information that we have collected,
            including personal information, would be transferred to the
            surviving entity in a merger or the acquiring entity. If we are
            involved in a merger, acquisition or asset sale, or in the unlikely
            event of a bankruptcy, liquidation or receivership of our business,
            and your personal information becomes subject to a different privacy
            policy, we will provide affected users notice through the Folksmedia
            Services.
          </p>
          <p className="lh-lg">
            Finally, we may also share such personal information where required
            to do so by law or subpoena or if we reasonably believe that such
            action is necessary to (a) comply with the law and the lawful
            requests by governmental, self-regulatory or other public
            authorities (including for the purpose of meeting national security
            or law enforcement requirements); (b) to enforce our terms of use or
            to protect the security, quality or integrity of the Folksmedia
            Services; and/or (c) to exercise or protect the rights, property, or
            personal safety of Folksmedia, our users, or others.
          </p>
        </div>
        <div>
          <h5 className="fw-bold">Accounts Payable and Collections</h5>
          <p className="lh-lg">
            It is your duty to make sure that you keep your account(s) in good
            standing with us. If you have an outstanding balance, or if you
            dispute a payment to us with your credit card or financial
            institution for any reason, please note that all information
            collected from your activity on our website may be disclosed to the
            bank and/or collections agency we work with for the purposes of
            establishing proof of users’ identity, proof of use, proof that
            service was provided and/or collecting any outstanding debt owed to
            us.
          </p>
        </div>{" "}
        <div>
          <h5 className="fw-bold">Selling Personal Information</h5>
          <p className="lh-lg">
            Folksmedia will not rent or sell your personal information to third
            parties without your consent.
          </p>
        </div>
        <div>
          <h5 className="fw-bold">Cross-Border Storage and Processing</h5>
          <p className="lh-lg">
            The personal information we collect or receive may be stored and
            processed in the United States or any other country in which
            Folksmedia or its affiliates, or service providers maintain
            facilities. Folksmedia may transfer information that we collect
            about you, including personal information, to affiliated entities,
            or to other third parties across borders and from your country or
            jurisdiction to other countries or jurisdictions around the world.
            If you are located in the European Union or other regions with laws
            governing data collection and use that may differ from U.S. law,
            please note that we may transfer information, including personal
            information, to a country and jurisdiction that does not have the
            same data protection laws as your jurisdiction, and you consent to
            the transfer of information to the U.S. or any other country in
            which Folksmedia or its affiliates, or service providers maintain
            facilities and the use and disclosure of information about you as
            described in this Privacy Policy.
          </p>
        </div>
        <div>
          <h5 className="fw-bold">
            Opt-Out Choices, Notifications, and Retention of Information
          </h5>
          <p className="lh-lg">
            Folksmedia provides you with the opportunity to opt out of receiving
            certain types of communications. If you no longer want to receive
            notifications, change your preferences on your account settings or
            communicate your preferences to us via email at{" "}
            <a
              href="mailto:contact@folksmedia.com"
              target="_blank"
              rel="noreferrer"
              className="primary-text-color"
            >
              contact@folksmedia.com
            </a>{" "}
            . You may opt out of receiving promotional communications from us by
            following the instructions in those communications. If you do opt
            out, we may still send you non-promotional communications, such as
            information about your account or your use of the Folksmedia
            Services.
          </p>
          <p className="lh-lg">
            With your consent, we may send promotional and non-promotional push
            notifications or alerts to your mobile device. You can deactivate
            these messages at any time by changing the notification settings on
            your mobile device.
          </p>
          <p className="lh-lg">
            If you deactivate your account or request that Folksmedia delete
            your personal information, we may still retain certain data and
            information associated with your account for analytical purposes and
            record-keeping integrity, as well as to prevent fraud, collect any
            fees owed, enforce our terms and conditions, take actions we deem
            necessary to protect the integrity of our website or our users, or
            take other actions otherwise permitted by U.S. State and federal
            law. In addition, if certain information has already been provided
            to third parties as described in this Privacy Policy, retention of
            that information will be subject to those third parties’ policies.
          </p>
        </div>{" "}
        <div>
          <h5 className="fw-bold">How We Keep Personal Information Secure</h5>
          <p className="lh-lg">
            Your personal information is protected by commercial reasonable
            physical, electronic and procedural safeguards in compliance with
            applicable U.S. federal and state regulations and industry
            standards. We use computer safeguards such as firewalls and data
            encryption. In addition, we enforce physical access controls to our
            offices and files, and we do not authorize access to users’ personal
            information except for those personnel who require such access to
            fulfill their job responsibilities. We do not currently collect or
            store any user payment information such as credit card information,
            but have selected third party online payment services so that when
            you place an order online, your personal details and credit card
            information are encrypted using SSL encryption technology before
            being sent over the Internet. Although we cannot guarantee
            encryption or the privacy of your personal details, SSL makes it
            very difficult for your information to be stolen or intercepted
            while being transferred. All information sent to our credit card
            payment processors is in the secure SSL environment, which will
            protect it against disclosure to third parties. Despite our efforts
            to ensure security, we cannot guarantee that personal information
            may not be accessed, disclosed, altered or destroyed by breach of
            our administrative, managerial and technical safeguards. Therefore,
            we urge you to take adequate precautions to protect your personal
            data as well, including, without limitation, never sharing the
            username and password used in connection with your participation in
            the Folksmedia Services. If we learn of a system security breach, we
            may attempt to notify you electronically. By using the Folksmedia
            Services, you agree that we may communicate with you electronically.
            Folksmedia may post a notice on its website(s), mobile
            application(s) and/or other services if a security breach occurs. We
            may also send an email to you at the email address you have provided
            to us. Depending on where you live, you may have a legal right to
            receive notice of a security breach in writing. To receive free
            written notice of a security breach (or to withdraw your consent
            from receiving electronic notice of a security breach), you should
            notify us of your request by emailing us at{" "}
            <a
              href="mailto:contact@folksmedia.com"
              target="_blank"
              rel="noreferrer"
              className="primary-text-color"
            >
              contact@folksmedia.com
            </a>{" "}
            .
          </p>
        </div>
        <div>
          <h5 className="fw-bold">Information from Minors</h5>
          <p className="lh-lg">
            We do not knowingly solicit, collect or share information from any
            individuals under the age of 18.
          </p>
        </div>
        <div>
          <h5 className="fw-bold">
            Rights of California Consumers and Residents
          </h5>
          <p className="lh-lg">
            Under California law, specific disclosures are required, and
            California residents have additional rights regarding their personal
            information. You can download a printable copy of this Privacy
            Policy
          </p>
          <i>California Consumer Protection Act</i>
          <p className="lh-lg">
            The following details the categories of Personal information that we
            collect and have collected over the past twelve (12) months: contact
            information, including full name and email address; information you
            submitted for purposes of accessing or using the Products; location
            information and Internet, including geolocation data; and customer
            service information.
          </p>
          <p className="lh-lg">
            Under the California Consumer Protection Act of 2018 as amended
            (“CCPA”), a California consumer has the following special rights:
          </p>
          <ul className="lh-lg dashed">
            <li>
              right to request that the business disclose what personal
              information it collects, uses, discloses, and sells;
            </li>
            <li>
              right to request the deletion of their personal information
              collected by the business; and
            </li>
            <li>
              the right, at any time, to direct a business that sells personal
              information about the consumer to third parties not to sell the
              consumer’s personal information (“right to opt-out”).
            </li>
          </ul>
          <p className="lh-lg">
            Over the past twelve (12) months, we have not disclosed any personal
            information to any third parties, but in the future we expect to
            disclose the following categories of personal information to service
            providers or other parties for business purposes: contact
            information, including full name and email address; information you
            submitted for purposes of accessing or using the Products.
          </p>{" "}
          <p className="lh-lg">
            Based on our understanding of the term "sell" under the CCPA, we do
            not sell your personal information.
          </p>{" "}
          <p className="lh-lg">
            For any of the above-mentioned rights, you may designate an
            authorized agent to make a request on your behalf. In the request,
            you or your authorized agent must provide information sufficient for
            us to confirm the identity of an authorized agent. We are required
            to verify that your agent has been properly authorized to request
            information on your behalf and this may take additional time to
            fulfil your request.
          </p>
          <p className="lh-lg">
            To exercise their rights and request copies of the data collected
            about them in the past 12 months, that their personal information
            collected by Adcuratio be deleted or to exercise their right of opt
            out, California residents may make a request at{" "}
            <a
              href="mailto:contact@folksmedia.com"
              target="_blank"
              rel="noreferrer"
              className="primary-text-color"
            >
              contact@folksmedia.com
            </a>{" "}
            . Such request must include sufficient information to allow us to
            verify that you are the person about whom we have collected personal
            information and describe your request in sufficient detail to allow
            us to understand, evaluate, and respond to it. Opt-out requests may
            be cancelled by making an opt-in request via your user account or by
            contacting us at{" "}
            <a
              href="mailto:contact@folksmedia.com"
              target="_blank"
              rel="noreferrer"
              className="primary-text-color"
            >
              contact@folksmedia.com
            </a>{" "}
            .
          </p>
          <p className="lh-lg">
            We will work to respond to your valid request within 45 days of
            receipt. We will not charge you a fee for making a valid request
            unless your valid request(s) is excessive, repetitive, or manifestly
            unfounded. If we determine that your valid request warrants a fee,
            we will notify you of the fee and explain that decision before
            completing your request.
          </p>{" "}
          <p className="lh-lg">
            We will use the information you provide to make your CCPA rights
            requests to verify your identity, identify the personal information
            we may hold about you and act upon your request. We strongly
            recommend that you submit the email that you used when you
            registered with the Services. After you submit a CCPA rights
            requests using one of our forms, you will be required to verify
            access to the email address you submitted. You will receive an email
            with a follow-up link to complete your email verification process.
            You are required to verify your email in order for us to proceed
            with your CCPA rights requests. Please check your spam or junk
            folder in case you can't see the verification email in your inbox.
            If you are a California resident and have any questions regarding
            your CCPA rights under this Privacy Policy, please contact us at{" "}
            <a
              href="mailto:contact@folksmedia.com"
              target="_blank"
              rel="noreferrer"
              className="primary-text-color"
            >
              contact@folksmedia.com
            </a>{" "}
            .
          </p>
          <i className="lh-lg">Right to be free from discrimination.</i>
          <p className="lh-lg">
            You may exercise any of the above rights without fear of being
            discriminated against. We are permitted to provide a different price
            or rate to you if the difference is directly related to the value
            provided to you by your data.
          </p>
          <i className="lh-lg">California Do Not Track Disclosure</i>
          <p className="lh-lg">
            Do Not Track is a privacy preference that users can set in their web
            browsers. When a user turns on the Do Not Track signal, the browser
            sends a message to websites requesting them not to track the user.
            We do not currently respond to Do Not Track browser settings or
            signals. For information about Do Not Track, please visit:{" "}
            <a
              href="www.allaboutdnt.org"
              target="_blank"
              rel="noreferrer"
              className="primary-text-color"
            >
              www.allaboutdnt.org{" "}
            </a>
          </p>
          <i className="lh-lg">California Online Privacy Protection Act</i>
          <p className="lh-lg">
            CalOPPA requires a person or company in the United States that
            operates websites collecting personally identifiable information
            from individual consumers residing in California to post a
            conspicuous privacy policy on its website stating exactly the
            information being collected and those individuals with whom it is
            being shared, and to comply with this Privacy Policy. See more at:{" "}
            <a
              href=" https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/#sthash.0FdRdT51.dpuf"
              target="_blank"
              rel="noreferrer"
              className="primary-text-color"
            >
              https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/#sthash.0FdRdT51.dpuf{" "}
            </a>
          </p>{" "}
          <p className="lh-lg">
            California Customers may request further information about our
            compliance with California’s privacy law, and may request to review
            and request changes to their personal information collected and
            stored by Adcuratio by e-mailing{" "}
            <a
              href="mailto:contact@folksmedia.com"
              target="_blank"
              rel="noreferrer"
              className="primary-text-color"
            >
              contact@folksmedia.com
            </a>{" "}
            or contacting us as stated below.
          </p>
        </div>
        <div>
          <h5 className="fw-bold">Modifications </h5>

          <p className="lh-lg">
            This Privacy Policy is effective as of the date stated at the top of
            this Privacy Policy. We may update this Privacy Policy from time to
            time. We will place any updates to this Privacy Policy on our
            website at{" "}
            <a
              href=" https://www.folksmedia.com/privacy-policy/"
              target="_blank"
              rel="noreferrer"
              className="primary-text-color"
            >
              https://www.folksmedia.com/privacy-policy/{" "}
            </a>{" "}
            We encourage you to periodically review this page for the latest
            information on our privacy practices. You can always check the “last
            updated” date at the top of this document to see when the Privacy
            Policy was last changed. You may also request a paper copy of the
            Privacy Policy by contacting us at the email or address noted below.
            You are bound by any changes to the Privacy Policy by using the
            Folksmedia Services after such changes have been posted.
          </p>
        </div>
        <div>
          <h5 className="fw-bold">Contact Us</h5>

          <p className="lh-lg">
            To communicate with our personnel responsible for data protection
            and compliance, please email{" "}
            <a
              href="mailto:contact@folksmedia.com"
              target="_blank"
              rel="noreferrer"
              className="primary-text-color"
            >
              contact@folksmedia.com
            </a>
          </p>
        </div>
        <p className="paragraph-text fw-bold mt-5 text-center primary-text-color">
          Folksmedia © {currentYear}
        </p>
      </div>
    </div>
  )
}
