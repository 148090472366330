import * as React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import PrivacyPolicy from "../sections/PrivacyPolicy"

export default function aboutPrivacyPolicy() {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Privacy Policy</title>
      </Helmet>
      <Layout>
        <PrivacyPolicy />
      </Layout>
    </div>
  )
}
